/* .construction-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: start;
    position: relative;

} */

:root {
    --size: 150px;

}

:root {
    --size: 150px;
}

.construction-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

span {
    animation: color-animation 8s linear infinite;
}

span {
    --color-1: #91b4f0;
    /* Light Blue */
    --color-2: #f4cc3d;
    /* Lighter Blue */
    --color-3: #ff989e;
    /* Pale Blue */

}

@keyframes color-animation {
    0% {
        color: var(--color-1)
    }

    32% {
        color: var(--color-1)
    }

    33% {
        color: var(--color-2)
    }

    65% {
        color: var(--color-2)
    }

    66% {
        color: var(--color-3)
    }

    99% {
        color: var(--color-3)
    }

    100% {
        color: var(--color-1)
    }
}

.construction-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}



.text-container {
    display: flex;
    flex-direction: column;
    background-color: #fafaf8;
    border-radius: 2px;
    padding: 5px 25px 55px 25px;
    max-width: fit-content;
    color: #181818;
    font-family: 'Poppins', sans-serif;
    position: relative;
    height: 40rem;
    box-shadow: 0 0 0 8px #6276e6, -35px 35px 0px 0px #ffd336;
    position: absolute;
}




h1 {
    font-size: 6.4rem !important;
    font-weight: 900 !important;
    text-transform: uppercase;
    line-height: 8rem !important;
    letter-spacing: .2rem;
    margin: 0;
    color: #6276e6 !important;
}

h2 {
    font-size: 2.6rem !important;
    font-weight: 400 !important;
    text-align: start;
    letter-spacing: .2rem;
    padding-top: -.3em;
    color: #6276e6 !important;
    /* margin-left: 10px; */

}

p,
.social-bar {
    position: absolute;
    margin: 10px 0;
}

#root>div>div>div.text-container>p {
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .1rem;
    bottom: 65px;
    color: #6276e6;
}


.social-bar {
    margin: 20px 0;
    bottom: 0;
}

#root>div>div>div>div>a {
    text-decoration: none;
}

#root>div>div>div>div>a>div {
    background-color: rgb(255, 255, 255);
    /* border: 1px solid rgb(33, 83, 245); */
    color: #6276e6;
    border-color: #6276e6;
    font-size: 16px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 15rem;
    font-weight: 800;
}

#root>div>div>div>div>a>div:hover {
    background-color: #6276e6;
    border: 1px solid #6276e6;
    color: #fff;
}

.developed-by {
    position: absolute;
    right: 15.5em;
    bottom: -3em;
}

.developed-by p {
    color: #ffffff8f;
    background-color: #434343dd;
    border: 1px solid rgba(250, 250, 250, 0.453);
    border-radius: 8px;
    width: 10rem;
    text-align: center;
}


.area {
    background: #4e54c8;
    background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
    width: 100%;
    height: 100vh;
}

.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;

}

.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}

@media (max-width: 1010px) {

    div.text-container {
        min-width: 90vw !important;
        min-height: 75vh !important;
    }

    h1 {
        font-size: 5.4rem !important;
    }

}

@media (max-width: 862px) {

    div.text-container {
        min-width: 90vw !important;
        min-height: 78vh !important;
    }
    h1 {
        font-size: 5rem !important;
        letter-spacing: .05rem;
    }

}

@media (max-width: 805px) {    
    div.text-container {
        min-width: 90vw !important;
        min-height: 78vh !important;
        box-shadow: 0 0 0 8px #6276e6, -15px 35px 0px 0px #ffd336;

    }
    h1 {
        font-size: 4.6rem !important;
    }
}

@media (max-width: 713px) {
    h1 {
        font-size: 3.6rem !important;
        line-height: 7.9rem !important;
    }

    h2 {
        font-size: 2rem !important;
    }
}

@media (max-width: 571px) {
    div.text-container {
        min-width: 90vw !important;
        min-height: 78vh !important;
        box-shadow: 0 0 0 8px #6276e6, 0px 55px 0px 0px #ffd336;

    }
    h1 {
        font-size: 3.3rem !important;
        line-height: 7.5rem !important;
    }

    h2 {
        font-size: 2rem !important;
    }
}

@media (max-width: 529px) {
    h1 {
        font-size: 3.0rem !important;
        line-height: 7.5rem !important;
    }

    h2 {
        font-size: 2rem !important;
    }
}

@media (max-width: 489px) {
    h1 {
        font-size: 2.8rem !important;
        line-height: 4.5rem !important;
    }

    h2 {
        font-size: 1.6rem !important;
    }
}